import { useEffect, useState } from "react";
// import { Row,Col } from "antd";
import { formatDate } from "../utils";

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    document.title = "费用清单";
    setExpenses(JSON.parse(window.localStorage.getItem("expenses")));
  }, []);

  const renderPhoneDescription = (label, value) => {
    return (
      <div
        key={label}
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span span={10} style={{ color: "#999" }}>
          {label}
        </span>
        <span span={14} style={{ fontWeight: 500 }}>
          {value}
        </span>
      </div>
    );
  };

  return (
    <div style={{ padding: 10 }}>
      {expenses?.map((expense) => (
        <div
          style={{
            background: "#fff",
            padding: 20,
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <img
            src={
              expense?.attachment_list?.find((a) => a?.fileType === "PREVIEW")
                ?.tempUrl
            }
            width="100%"
            alt=""
          />
          {expense?.customer?.register_name &&
            renderPhoneDescription(
              "开票单位",
              expense?.customer?.register_name
            )}
          {expense?.invoice_date &&
            renderPhoneDescription(
              "开票日期",
              formatDate(expense?.invoice_date, "YYYY-MM-DD")
            )}
          {renderPhoneDescription(
            `${expense?.category_desc}-${expense?.purpose_desc}`,
            expense?.reimbursement_amount
          )}
        </div>
      ))}
    </div>
  );
};

export default Expenses;
