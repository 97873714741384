import React, { useEffect, useState } from "react";
import Report from "../Report";
import EmailPage from "../emailPage";
import GuidePage from "../guidePage";
import Brain from "../brain";
import FpbPromotePage from "../fpbPromotePage";
import NoticePage from "../noticePage";
import DingtalkadPage from "../dingtalkadPage";
import DingTalkAuditDetail from "../DingTalkAuditDetail";
import DingTalkAuditDetailList from "../DingTalkAuditDetail/expenses";
import Yunpiao from "../yunpiao";
import Yunpiaopc from "../yunpiaopc";
import PromoteYunpiaoPage from "../promoteYunpiaoPage";
import MoneyPage from '../moneyPage';

import "./index.css";

function App() {
  const [isPhone, setIsPhone] = useState(false);

  useEffect(() => {
    const setHtmlFontSize = () => {
      const htmlDom = document.getElementsByTagName("html")[0];
      let htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (htmlWidth >= 750) {
        htmlWidth = 750;
      }
      if (htmlWidth <= 320) {
        htmlWidth = 320;
      }
      htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
    };

    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );

    setIsPhone(flag);

    window.onresize = setHtmlFontSize;

    if (flag) setHtmlFontSize();
  }, []);

  return (
    <div>
      {window.location.pathname === "/brain" && <Brain isPhone={isPhone} />}
      {window.location.pathname === "/report" && <Report isPhone={isPhone} />}
      {window.location.pathname === "/email" && <EmailPage />}
      {window.location.pathname === "/guide" && <GuidePage />}
      {window.location.pathname === "/promote" && (
        <PromoteYunpiaoPage isPhone={isPhone} />
      )}
      {window.location.pathname === "/notice-page" && (
        <NoticePage isPhone={isPhone} />
      )}
      {window.location.pathname === "/fpb-promote" && (
        <FpbPromotePage isPhone={isPhone} />
      )}
      {window.location.pathname === "/dingtalkad" && <DingtalkadPage />}
      {window.location.pathname === "/dingtalk-audit-detail" && (
        <DingTalkAuditDetail isPhone={isPhone} />
      )}
      {window.location.pathname === "/dingtalk-audit-detail/list" && (
        <DingTalkAuditDetailList isPhone={isPhone} />
      )}
      {window.location.pathname === "/yunpiao" && <Yunpiao isPhone={isPhone} />}
      {window.location.pathname === "/yunpiao-pc" && (
        <Yunpiaopc isPhone={isPhone} />
      )}
      {window.location.pathname === "/yunpiao-promote" && (
        <PromoteYunpiaoPage isPhone={isPhone} />
      )}
       {window.location.pathname === "/money" && (
        <MoneyPage isPhone={isPhone} />
      )}
    </div>
  );
}

export default App;
