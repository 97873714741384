import React from "react";
import axios from "axios";
import { Component } from "react";
import {
  Button,
  Card,
  Descriptions,
  Table,
  Modal,
  Col,
  Row,
  message,
  Input,
} from "antd";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  RightOutlined,
} from "@ant-design/icons";
import { formatPrice, formatDate, baseUrl, GetQueryString } from "../utils";
import RuleScoreSummary from "./Components/RuleScoreSummary";
import InvoiceTable from "./Components/InvoiceTable";
import ActivityCollapse from "./Components/ActivityCollapse";
import WAITINGAPPROVAL from "../assets/images/WAITINGAPPROVAL.svg";
import REIMBURSED from "../assets/images/REIMBURSED.svg";
import APPROVED from "../assets/images/APPROVED.svg";
import UNMAKE from "../assets/images/UNMAKE.svg";
import REJECTED from "../assets/images/REJECTED.svg";
import print_icon from "../assets/images/print_icon.png";
import comment_icon from "../assets/images/comment_icon.png";

const statusTagEurm = {
  PENDINGAPPROVAL: WAITINGAPPROVAL,
  APPROVED: APPROVED,
  PAID: REIMBURSED,
  UNMAKE: UNMAKE,
  REJECTED: REJECTED,
};

const statusTagStyle = {
  width: 180,
  position: "absolute",
  top: 10,
  right: 10,
};

const documentType = {
  requisition: "requisition",
  expense: "expenseClaim",
  payform: "payForm",
};

const title = [
  {
    name: "companyName",
    title: "公司名称",
  },
];

const tripModes = {
  TRAIN: "火车/高铁",
  PLAIN: "飞机",
  BUS: "汽车",
  "SELF-DRIVER": "自驾车",
  OTHER: "其他",
};

// 付款申请单类型
const payFormTypes = {
  PAY_PREPAY: "无票预付",
  PAY_ARRIVAL: "有票支付",
  FINAL_PAY: "尾款支付",
};

class DingTalkAuditDetail extends Component {
  state = {
    id: "",
    type: "",
    detail: {},
    workflowNodes: [],
    xrenderSchema: {},
    imgSrc: [],
    imgSrcList: [],
    expenseDetail: [],
    formJson: [],
    cityItems: [],
    formJsonArray: [],
    errorImgFile: [],
    businessList: [],
    ruleList: [],
    attachMentUrl: null,
    attachMentvisible: false,
    refuseReason: null,
    showButton: false,
    buttonLoading: "",
  };

  componentDidMount() {
    document.title = "审批";
    this.setState(
      {
        id: GetQueryString("id"),
        type: GetQueryString("type"),
      },
      this.fetchData
    );
  }

  getCities = (list) => {
    let cities = [];
    list?.forEach((item) => {
      item?.children?.forEach((city) => {
        cities.push({
          cityName: city.value,
          cityCode: city.key,
        });
      });
    });
    this.setState({
      cityItems: cities,
    });
  };

  getBusiness = (list) => {
    const { businessList } = this.state;
    let newList = businessList;
    for (let i = 0; i < list?.length; i++) {
      if (list[i]?.id) {
        newList.push({
          id: list[i]?.id,
          name: list[i]?.name,
        });
      }
      this.setState({
        businessList: newList,
      });
      if (list[i]?.children?.length > 0) {
        this.getBusiness(list[i]?.children);
      }
    }
  };

  getPrintUrl = () => {
    this.setState({ buttonLoading: "print" });
    axios
      .get(
        `${baseUrl()}/accounting-ecs/api/v1/reports/${
          this.state.detail?.reference_report_id
        }/third-print?reportType=${
          this.state.detail?.claim_type
        }&instanceId=${GetQueryString("instanceId")}&taskId=${GetQueryString(
          "taskId"
        )}`
      )
      .then((res) => {
        this.setState({ buttonLoading: "" });
        if (res.data.code === 0) {
          window.location.href = `/report?key=${res?.data?.data?.key}`;
        } else {
          message.error(res?.data?.message);
        }
      });
  };

  handleComment = () => {
    this.setState({ buttonLoading: "comment" });
    axios
      .post(
        `${baseUrl()}/purchases-service/api/v1/claim/tasks/${GetQueryString(
          "taskId"
        )}/comment?instanceId=${GetQueryString("instanceId")}`,
        {
          message: this.state.refuseReason,
        }
      )
      .then((res) => {
        this.setState({ buttonLoading: "" });
        if (res?.data?.code === 0) {
          message.success("已评论");
          this.fetchData();
        } else {
          message.error(res?.data?.message);
        }
      });
  };

  handleApprove = () => {
    this.setState({ buttonLoading: "approve" });
    axios
      .put(
        `${baseUrl()}/purchases-service/api/v1/claim/${this.state.id}?type=${
          this.state.type
        }&instanceId=${GetQueryString("instanceId")}&taskId=${GetQueryString(
          "taskId"
        )}`,
        {
          action: "APPROVED",
        }
      )
      .then((res) => {
        this.setState({ buttonLoading: "" });
        if (res?.data?.code === 0) {
          message.success("已通过");
          this.fetchData();
        } else {
          message.error(res?.data?.message);
        }
      });
  };

  handleReject = () => {
    if (!this.state.refuseReason) {
      message.warn("请输入拒绝原因");
      return;
    }
    this.setState({ buttonLoading: "reject" });
    axios
      .put(
        `${baseUrl()}/purchases-service/api/v1/claim/${this.state.id}?type=${
          this.state.type
        }&instanceId=${GetQueryString("instanceId")}&taskId=${GetQueryString(
          "taskId"
        )}`,
        {
          action: "REJECTED",
          refuseReason: this.state.refuseReason,
        }
      )
      .then((res) => {
        this.setState({ buttonLoading: "" });
        if (res?.data?.code === 0) {
          message.success("已拒绝");
          this.setState({ refuseReason: null });
          this.fetchData();
        } else {
          message.error(res?.data?.message);
        }
      });
  };

  fetchData = () => {
    axios
      .get(
        `${baseUrl()}/purchases-service/api/v1/claim/${this.state.id}?type=${
          this.state.type
        }&instanceId=${GetQueryString("instanceId")}&taskId=${GetQueryString(
          "taskId"
        )}`
      )
      .then((res) => {
        if (res.data.code === 0) {
          this.getCities(res?.data?.data?.cities);
          this.getBusiness(res?.data?.data?.bizDepts);
          this.setState(
            {
              detail: res?.data?.data[documentType[this.state.type]],
              workflowNodes: res?.data?.data?.workflowNodes,
              xrenderSchema: res?.data?.data?.xrenderSchema,
              showButton: res?.data?.data?.showButton,
            },
            this.refreshDetail
          );
          window.localStorage.setItem(
            "expenses",
            JSON.stringify(
              res?.data?.data[documentType[this.state.type]]?.expense_list
            )
          );
        }
      });
  };

  refreshDetail = async () => {
    const res = {
      data: this.state.detail,
    };
    let imgSrc = [];
    await res?.data?.expense_list?.forEach((item) => {
      imgSrc.push({
        id: item.id,
        previewLink:
          item?.attachment_list?.find((v) => v.fileType === "PREVIEW")
            ?.tempUrl ?? null,
      });
    });
    let imgList = [];
    await res?.data?.expense_list?.forEach((item) => {
      imgList.push({
        id: item.id,
        token: item?.attachment_list,
      });
    });
    this.setState({
      imgSrcList: imgList,
      expenseDetail: res?.data?.expense_list,
      imgSrc,
    });
    let rList = res?.data?.rule_check_result?.rule_check_groups;
    this.setState({
      ruleList: rList,
    });
    let detail = res?.data?.form_json
      ? JSON.parse(res?.data?.form_json)
      : res?.data?.form_data;

    const res3 = {
      data: this.state.xrenderSchema,
    };

    if (res3) {
      let detailList = []; //动态表单
      let detailArray = []; //动态表单中数组类型额外展示
      for (let i in detail) {
        if (
          res3.data?.properties &&
          Object.keys(res3.data?.properties)?.find((v) => v === i)
        ) {
          if (
            res3.data?.properties[
              Object.keys(res3.data?.properties)?.find((v) => v === i)
            ]?.type === "array"
          ) {
            let arrayList = detail[i];
            let detailArrayItem = [];
            let arryProperties =
              res3.data?.properties[
                Object.keys(res3.data?.properties)?.find((v) => v === i)
              ]?.items?.properties;
            arrayList.forEach((it) => {
              let keyList = Object.keys(it);
              let itemParams = { ...it };
              for (let it3 in it) {
                itemParams[`widget/` + it3] = arryProperties[it3]?.widget;
                itemParams["key/" + it3] = it3;
                itemParams["keyName/" + it3] = arryProperties[it3]?.title;
                itemParams.keyList = keyList;
              }
              detailArrayItem.push(itemParams);
            });
            detailArray.push({
              type: res3.data?.properties[
                Object.keys(res3.data?.properties)?.find((v) => v === i)
              ]?.title,
              array: detailArrayItem,
            });
          } else {
            detailList.push({
              name: res3.data?.properties[
                Object.keys(res3.data?.properties)?.find((v) => v === i)
              ]?.title,
              info: detail[i] ? detail[i] : null,
              widget:
                res3.data?.properties[
                  Object.keys(res3.data?.properties)?.find((v) => v === i)
                ]?.widget,
              id: res3.data?.properties[
                Object.keys(res3.data?.properties)?.find((v) => v === i)
              ]?.name,
            });
          }
        } else {
          if (i === "companyName") {
            detailList.push({
              name: i,
              info: detail[i],
            });
          }
        }
      }
      this.setState(
        {
          formJson: detailList,
          formJsonArray: detailArray,
        },
        console.log(this.state.formJson, this.state.formJsonArray)
      );
    }
  };

  tableColumn = (list) => {
    const { businessList, cityItems } = this.state;
    let column = [];
    list.forEach((item) => {
      item?.keyList?.forEach((item0) => {
        if (
          !column?.find((v) => v.dataIndex === item0) &&
          item[`keyName/` + item0]
        ) {
          column.push({
            title: item[`keyName/` + item0],
            dataIndex: item0,
            render: (text, record) => (
              <div>
                {text instanceof Array ? (
                  <div>
                    {record["widget/" + item0] === "participant" ? (
                      <div>{this.getParticipant(text)}</div>
                    ) : (
                      text &&
                      text[0] &&
                      text[1] && (
                        <div>
                          从 {text[0]} 到 {text[1]}
                        </div>
                      )
                    )}
                  </div>
                ) : record["widget/" + item0] === "addresspicker" ? (
                  <div>
                    {cityItems?.find((v) => v.cityCode === text)?.cityName}
                  </div>
                ) : record["widget/" + item0] === "businessSelect" ? (
                  <div>
                    {text?.substr(0, text?.indexOf("~")) &&
                      businessList?.find(
                        (v) => v.id === text?.substr(0, text?.indexOf("~"))
                      )?.name}
                    ~
                    {text?.substr(text?.indexOf("~") + 1, text?.length) &&
                      businessList?.find(
                        (v) =>
                          v.id ===
                          text?.substr(text?.indexOf("~") + 1, text?.length)
                      )?.name}
                  </div>
                ) : record["widget/" + item0] === "accounts_receivable" ? (
                  <div>{text?.account_number}</div>
                ) : (
                  <div>{text}</div>
                )}
              </div>
            ),
          });
        }
      });
    });
    return column;
  };

  getParticipant = (list) => {
    let people = [];
    for (let i = 0; i < list?.length; i++) {
      people.push(list[i]?.userName);
    }
    return people?.join("、");
  };

  renderFormItem = (item) => {
    const { cityItems, businessList } = this.state;

    if (item?.widget === "participant") {
      return <div>{this.getParticipant(item?.info)}</div>;
    }

    if (item?.widget === "addresspicker") {
      return (
        <div>{cityItems?.find((v) => v.cityCode === item?.info)?.cityName}</div>
      );
    }

    if (item.widget === "timepicker" && item?.info) {
      return (
        <div>
          从 {item?.info[0]} 到 {item?.info[1]}
        </div>
      );
    }

    if (item?.widget === "businessSelect") {
      console.log(item?.info, 9999);
      console.log(businessList, 9999);
      return (
        <div>
          {item?.info?.substr(0, item?.info?.indexOf("~")) &&
            businessList?.find(
              (v) => v.id === item?.info?.substr(0, item?.info?.indexOf("~"))
            )?.name}
          ~
          {item?.info?.substr(
            item?.info?.indexOf("~") + 1,
            item?.info?.length
          ) &&
            businessList?.find(
              (v) =>
                v.id ===
                item?.info?.substr(
                  item?.info?.indexOf("~") + 1,
                  item?.info?.length
                )
            )?.name}
        </div>
      );
    }

    if (item?.widget === "accounts_receivable") {
      return (
        <div>
          {item?.info?.account_name}&nbsp;
          {item?.info?.account_number}&nbsp;
          {item?.info?.bank_name}
        </div>
      );
    }

    if (item.widget === "department" || item.widget === "corp") {
      return <div>{item?.info?.name}</div>;
    }

    if (item.widget === "attachment") {
      return item?.info?.map((atttach) => (
        <img
          key={atttach?.key}
          onClick={() =>
            this.setState({
              attachMentvisible: true,
              attachMentUrl: atttach?.tempLink,
            })
          }
          src={atttach?.tempLink}
          style={{ width: 30, height: 30 }}
          alt=""
        />
      ));
    }

    if (item.widget === "requisition") {
      return item?.info?.map((requisition) => (
        <div key={requisition?.key}>
          {requisition?.title}: {requisition?.reason}
        </div>
      ));
    }

    if (item.widget === "feishuRequisitions") {
      return item?.info?.map((feishuRequisitions) => (
        <div key={feishuRequisitions?.id}>
          {feishuRequisitions?.template_code === "trip_approval" &&
            "出差申请单："}
          {feishuRequisitions?.template_code === "work_approval" &&
            "加班申请单："}
          {feishuRequisitions?.reason ?? "-"}
        </div>
      ));
    }

    if (item.widget === "supplier") {
      return (
        <div>
          {item?.info?.supplierName} {item?.info?.bankName}{" "}
          {item?.info?.accountNumber}
        </div>
      );
    }

    return <div>{item?.info}</div>;
  };

  ruleDetail = (recordList) => {
    const ruleColumn = [
      {
        title: "规则",
        dataIndex: "rule_desc",
        render: (text, record) => (
          <div style={{ cursor: "pointer" }}>
            {text ? text : record?.ruleName}
          </div>
        ),
      },
      {
        title: "评估结果",
        dataIndex: "evaluationResult",
        render: (text, record) => (
          <div style={{ cursor: "pointer" }}>
            {record?.error_msg ? (
              <ExclamationCircleFilled style={{ color: "#ffcc66" }} />
            ) : (
              <CheckCircleFilled style={{ color: "#52c41a" }} />
            )}
          </div>
        ),
      },
      {
        title: "信息",
        dataIndex: "error_msg",
        render: (text) => (
          <div style={{ cursor: "pointer" }}>{text ? text : "OK"}</div>
        ),
      },
    ];

    return (
      <Table
        rowKey={(record) => record?.rule_name}
        columns={ruleColumn}
        dataSource={recordList?.rule_check_items}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ paddingLeft: 8 }}>
              {this.ruleExpend(record?.error_invoices)}
            </div>
          ),
          rowExpandable: (record) => record?.error_msg !== null,
          expandRowByClick: true,
        }}
      />
    );
  };

  fileType = (name) => {
    const filetype = name?.substring(name?.lastIndexOf(".") + 1);
    return filetype;
  };

  findExpenseCustom = (invoiceNo) => {
    const { expenseDetail } = this.state;
    let customer;
    expenseDetail?.forEach((item) => {
      if (item?.invoice_no === invoiceNo) {
        customer = item?.customer?.register_name;
      }
    });
    return customer;
  };

  findExpenseDate = (invoiceNo) => {
    const { expenseDetail } = this.state;
    let date;
    expenseDetail?.forEach((item) => {
      if (item?.invoice_no === invoiceNo) {
        date = item?.invoice_date;
      }
    });
    return date;
  };

  findExpenseAmount = (invoiceNo) => {
    const { expenseDetail } = this.state;
    let amount;
    expenseDetail?.forEach((item) => {
      if (item?.invoice_no === invoiceNo) {
        amount = item?.invoice_amount;
      }
    });
    return amount;
  };

  findExpenseFile = (invoiceNo) => {
    const { expenseDetail } = this.state;
    let list;
    expenseDetail?.forEach((item) => {
      if (item?.invoice_no === invoiceNo) {
        list = item?.attachment_list;
      }
    });
    return list;
  };

  findExpenseReAmount = (invoiceNo) => {
    const { expenseDetail } = this.state;
    let amount;
    expenseDetail?.forEach((item) => {
      if (item?.invoice_no === invoiceNo) {
        amount = item?.reimbursement_amount;
      }
    });
    return amount;
  };

  ruleExpend = (recordList) => {
    const ruleExpensColumn = [
      {
        title: "发票",
        dataIndex: "fapiao",
        render: (_text, record) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "20px", width: "40%" }}>
                <div
                  style={{
                    width: "99%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {this.findExpenseCustom(record?.invoice_no)}
                </div>
                <div>
                  {this.findExpenseDate(record?.invoice_no) &&
                    formatDate(
                      this.findExpenseDate(record?.invoice_no),
                      "YYYY-MM-DD"
                    )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "发票金额(元)",
        dataIndex: "invoiceAmount",
        render: (text, record) =>
          this.findExpenseAmount(record?.invoice_no) &&
          formatPrice(this.findExpenseAmount(record?.invoice_no)),
      },
      {
        title: "报销金额(元)",
        dataIndex: "reimbursement_amount",
        render: (text, record) =>
          this.findExpenseReAmount(record?.invoice_no) &&
          formatPrice(this.findExpenseReAmount(record?.invoice_no)),
      },
    ];
    return (
      <Table
        columns={ruleExpensColumn}
        dataSource={recordList}
        pagination={false}
      />
    );
  };

  renderPhoneDescription = (label, value) => {
    return (
      <Row key={label} style={{ marginBottom: 10 }}>
        <Col span={10} style={{ color: "#999" }}>
          {label}
        </Col>
        <Col span={14} style={{ fontWeight: 500 }}>
          {value}
        </Col>
      </Row>
    );
  };

  renderRuleError = (rule_check_groups) => {
    let ruleList = [];
    let ruleOK = [];
    rule_check_groups?.forEach((item) => {
      item?.rule_check_items?.forEach((rule) => {
        if (rule?.warn_level === 1 && rule?.error_msg != null) {
          ruleList.push(rule?.error_msg);
        } else {
          ruleOK.push(rule?.rule_name);
        }
      });
    });
    return {
      ruleList,
      ruleOK,
    };
  };

  render() {
    const {
      detail,
      imgSrc,
      formJson,
      cityItems,
      // formJsonArray,
      businessList,
      ruleList,
      type,
      showButton,
      buttonLoading,
    } = this.state;

    const ruleSummary = [
      {
        title: "名称",
        dataIndex: "group_name",
        render: (text) => <div style={{ cursor: "pointer" }}>{text}</div>,
      },
      {
        title: "总规则数（项）",
        dataIndex: "total_num",
        render: (text) => (
          <div style={{ fontWeight: "500", cursor: "pointer" }}>{text}</div>
        ),
      },
      {
        title: "校验通过（项）",
        dataIndex: "success_num",
        render: (text) => (
          <div style={{ fontWeight: "500", cursor: "pointer" }}>{text}</div>
        ),
      },
      {
        title: "规则告警（项）",
        dataIndex: "warn_num",
        render: (text) => (
          <div style={{ fontWeight: "500", cursor: "pointer" }}>
            {text > 0 ? (
              <span style={{ color: "#f3cd5b" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </div>
        ),
      },
    ];

    const columns = [
      {
        title: "编号",
        dataIndex: "pay_form_no",
      },
      {
        title: "提交人",
        dataIndex: "submitter_name",
      },
      {
        title: "单据类型",
        dataIndex: "title",
      },
      {
        title: "支付类型",
        dataIndex: "pay_type",
        render: (text) => (text ? payFormTypes[text] : "-"),
      },
      // {
      //   title: "描述",
      //   dataIndex: "reason",
      // },
      // {
      //   title: "提交时间",
      //   dataIndex: "submit_time",
      //   render: (text) => (
      //     <div> {text && formatDate(text, "YYYY-MM-DD HH:mm:ss")}</div>
      //   ),
      // },
      // {
      //   title: "供应商",
      //   dataIndex: "supplier_name",
      // },
      // {
      //   title: "总金额(元)",
      //   dataIndex: "total_amount",
      //   align: "right",
      //   render: (text) => text && formatPrice(text),
      // },
      {
        title: "付款金额(元)",
        dataIndex: "amount",
        align: "right",
        render: (text) => text && formatPrice(text),
      },
      // {
      //   title: "待核销金额(元)",
      //   dataIndex: "pending_pay_amount",
      //   align: "right",
      //   render: (text) => text && formatPrice(text),
      // },
    ];

    const tripGroupColumn = [
      {
        title: "出发城市",
        dataIndex: "start_city",
      },
      {
        title: "到达城市",
        dataIndex: "target_city",
      },
      {
        title: "出行方式",
        dataIndex: "trip_mode",
        render: (text) => <div>{tripModes[text] ?? "-"}</div>,
      },
      {
        title: "单程/往返",
        dataIndex: "round_trip",
        render: (text) => <div> {text ? "单程" : "往返"}</div>,
      },
      {
        title: "开始时间",
        dataIndex: "start_time",
        render: (text) => (
          <div> {text && formatDate(text, "YYYY-MM-DD HH:mm:ss")}</div>
        ),
      },
      {
        title: "结束时间",
        dataIndex: "end_time",
        render: (text) => (
          <div> {text && formatDate(text, "YYYY-MM-DD HH:mm:ss")}</div>
        ),
      },
      {
        title: "时长/天",
        dataIndex: "travel_days",
      },
    ];

    return (
      <div
        style={{
          width: "100%",
          margin: "0 auto",
          paddingBottom: 80,
        }}
      >
        {document.body.clientWidth > 640 ? (
          <Card style={{ width: "98%", margin: "0 auto", marginBottom: 10 }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: 500,
                }}
              >
                {detail?.description || detail?.title}
              </div>
              <Descriptions
                bordered
                title="基本信息"
                labelStyle={{ fontWeight: "500" }}
              >
                {formJson.map((item) =>
                  item?.id === "reimbursement_instructions" ? (
                    <Descriptions.Item
                      label={item?.name}
                      span={3}
                      key={item?.name}
                    >
                      {item?.info}
                    </Descriptions.Item>
                  ) : null
                )}
                {formJson.map((item) =>
                  item?.id === "reimbursement_person" ? (
                    <Descriptions.Item
                      label={item?.name}
                      span={2}
                      key={item?.name}
                    >
                      {item?.info}
                    </Descriptions.Item>
                  ) : null
                )}
                {type === "expense" ? (
                  <>
                    <Descriptions.Item
                      label="发票金额（元）"
                      span={2}
                      contentStyle={{ fontWeight: "500" }}
                    >
                      {detail?.amount && formatPrice(detail?.amount)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="报销金额（元）"
                      span={2}
                      contentStyle={{ fontWeight: "500" }}
                    >
                      {detail?.reimbursement_total_amount &&
                        formatPrice(detail?.reimbursement_total_amount)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="实际报销金额（元）"
                      span={2}
                      contentStyle={{ fontWeight: "500" }}
                    >
                      {detail?.need_reimbursement_total_amount &&
                        formatPrice(detail?.need_reimbursement_total_amount)}
                    </Descriptions.Item>
                  </>
                ) : null}
                {formJson.map((item) =>
                  item?.id !== "reimbursement_instructions" &&
                  item?.id !== "reimbursement_person" &&
                  item?.id !== "tripGroup" ? (
                    <Descriptions.Item
                      label={
                        title.find((v) => v.name === item.name)?.title
                          ? title.find((v) => v.name === item.name)?.title
                          : item.name
                      }
                      span={2}
                      key={item.name}
                    >
                      {this.renderFormItem(item)}
                    </Descriptions.Item>
                  ) : null
                )}
                {type === "payform" ? (
                  <>
                    <Descriptions.Item label="支付类型" span={2}>
                      {detail?.pay_type &&
                        payFormTypes[detail?.pay_info?.pay_form_type]}
                    </Descriptions.Item>
                    <Descriptions.Item label="总金额（元）" span={2}>
                      {detail?.total_amount &&
                        formatPrice(detail?.total_amount)}
                    </Descriptions.Item>
                    <Descriptions.Item label="付款金额（元）" span={2}>
                      {detail?.amount && formatPrice(detail?.amount)}
                    </Descriptions.Item>
                    <Descriptions.Item label="待核销金额（元）" span={2}>
                      {detail?.pending_pay_amount &&
                        formatPrice(detail?.pending_pay_amount)}
                    </Descriptions.Item>
                  </>
                ) : null}
                {type !== "expense" ? (
                  <Descriptions.Item label="提单时间" span={2}>
                    {detail?.submit_time &&
                      formatDate(detail?.submit_time, "YYYY-MM-DD HH:mm:ss")}
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item label="提单时间" span={2}>
                    {detail?.claim_date &&
                      formatDate(detail?.claim_date, "YYYY-MM-DD HH:mm:ss")}
                  </Descriptions.Item>
                )}
              </Descriptions>
              {/* {formJsonArray?.length > 0 ? (
              <div>
                {formJsonArray?.map((item) => (
                  <div key={item.key}>
                    <div style={{ fontSize: "16px", fontWeight: 500 }}>
                      {item?.type}
                    </div>
                    <Table
                      dataSource={item?.array}
                      columns={this.tableColumn(item?.array)}
                      pagination={false}
                    />
                  </div>
                ))}
              </div>
            ) : null} */}
            </div>
            {type !== "requisition" ? (
              <>
                <div style={{ marginTop: "20px", display: "flex" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      marginBottom: 15,
                    }}
                  >
                    智能审核结果
                  </div>
                </div>
                <Table
                  rowKey={(record) => record?.group_name}
                  columns={ruleSummary}
                  dataSource={ruleList}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div style={{ paddingLeft: 8 }}>
                        {this.ruleDetail(record)}
                      </div>
                    ),
                    expandRowByClick: true,
                  }}
                  scroll={{ x: 600 }}
                />
                <RuleScoreSummary
                  score={detail?.rule_check_result?.score}
                  ruleList={ruleList}
                  suggest={detail?.rule_check_result?.score_name}
                />
                <div style={{ marginTop: "20px" }}>
                  <Descriptions
                    bordered
                    title="费用清单"
                    layout="vertical"
                    labelStyle={{ fontWeight: "500" }}
                  >
                    {null}
                  </Descriptions>
                </div>
                <InvoiceTable
                  detail={detail}
                  openFileDetail={this.openFileDetail}
                  fileType={this.fileType}
                  imgSrc={imgSrc}
                  businessList={businessList}
                  cityItems={cityItems}
                />
              </>
            ) : null}
            {detail?.pay_info?.write_off?.length ? (
              <div style={{ marginTop: "20px" }}>
                <Descriptions
                  bordered
                  title="关联核销单据"
                  layout="vertical"
                  labelStyle={{ fontWeight: "500" }}
                >
                  {null}
                </Descriptions>
                <Table
                  columns={columns}
                  dataSource={detail?.pay_info?.write_off}
                  pagination={false}
                  scroll={{ x: 800 }}
                />
              </div>
            ) : null}
            {detail?.form_data?.tripGroup &&
            detail?.form_data?.tripGroup?.length ? (
              <div style={{ marginTop: "20px" }}>
                <Descriptions
                  bordered
                  title="行程明细"
                  layout="vertical"
                  labelStyle={{ fontWeight: "500" }}
                />

                <Table
                  dataSource={detail?.form_data?.tripGroup}
                  columns={tripGroupColumn}
                  pagination={false}
                  scroll={{ x: 800 }}
                />
              </div>
            ) : null}
          </Card>
        ) : (
          <div style={{ padding: 10 }}>
            <div
              style={{
                background: "#fff",
                padding: 20,
                borderRadius: 10,
                marginBottom: 10,
              }}
            >
              <h2>{detail?.description || detail?.title}</h2>
            </div>
            <div
              style={{
                background: "#fff",
                padding: 20,
                borderRadius: 10,
                marginBottom: 10,
              }}
            >
              <h3>基本信息</h3>
              {formJson.map((item) =>
                item?.id === "reimbursement_instructions"
                  ? this.renderPhoneDescription(item?.name, item?.info)
                  : null
              )}
              {formJson.map((item) =>
                item?.id === "reimbursement_person"
                  ? this.renderPhoneDescription(item?.name, item?.info)
                  : null
              )}
              {type === "expense" ? (
                <>
                  {this.renderPhoneDescription(
                    "发票金额（元）",
                    detail?.amount && formatPrice(detail?.amount)
                  )}
                  {this.renderPhoneDescription(
                    "报销金额（元）",
                    detail?.reimbursement_total_amount &&
                      formatPrice(detail?.reimbursement_total_amount)
                  )}
                  {this.renderPhoneDescription(
                    "实际报销金额（元）",
                    detail?.need_reimbursement_total_amount &&
                      formatPrice(detail?.need_reimbursement_total_amount)
                  )}
                </>
              ) : null}
              {formJson.map((item) =>
                item?.id !== "reimbursement_instructions" &&
                item?.id !== "reimbursement_person" &&
                item?.id !== "tripGroup"
                  ? this.renderPhoneDescription(
                      title.find((v) => v.name === item.name)?.title
                        ? title.find((v) => v.name === item.name)?.title
                        : item.name,
                      this.renderFormItem(item)
                    )
                  : null
              )}
              {type === "payform" ? (
                <>
                  {this.renderPhoneDescription(
                    "支付类型",
                    detail?.pay_type &&
                      payFormTypes[detail?.pay_info?.pay_form_type]
                  )}
                  {this.renderPhoneDescription(
                    "总金额（元）",
                    detail?.total_amount && formatPrice(detail?.total_amount)
                  )}
                  {this.renderPhoneDescription(
                    "付款金额（元）",
                    detail?.amount && formatPrice(detail?.amount)
                  )}
                  {this.renderPhoneDescription(
                    "待核销金额（元）",
                    detail?.pending_pay_amount &&
                      formatPrice(detail?.pending_pay_amount)
                  )}
                </>
              ) : null}
              {type !== "expense"
                ? this.renderPhoneDescription(
                    "提单时间",
                    detail?.submit_time &&
                      formatDate(detail?.submit_time, "YYYY-MM-DD HH:mm:ss")
                  )
                : this.renderPhoneDescription(
                    "提单时间",
                    detail?.claim_date &&
                      formatDate(detail?.claim_date, "YYYY-MM-DD HH:mm:ss")
                  )}
            </div>
            {type !== "requisition" ? (
              <>
                <div
                  style={{
                    background: "#fff",
                    padding: 20,
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                >
                  <h3>智能审核结果</h3>
                  <div
                    style={{
                      background:
                        detail?.rule_check_result?.score === 100
                          ? "#D7F2CC"
                          : "#f5f5f5",
                      borderRadius: 5,
                    }}
                  >
                    {detail?.rule_check_result?.score === 100 ? (
                      <div
                        style={{
                          background: "#D7F2CC",
                          color: "#389E0D",
                          padding: 5,
                          borderRadius: 5,
                        }}
                      >
                        综合评分{detail?.rule_check_result?.score},
                        {detail?.rule_check_result?.score_name}
                      </div>
                    ) : (
                      <div className="enpense-warning-bg">
                        <h4
                          style={{
                            paddingLeft: 10,
                            paddingTop: 10,
                            marginBottom: 4,
                          }}
                        >
                          规则告警项（
                          {
                            this.renderRuleError(
                              detail?.rule_check_result?.rule_check_groups
                            )?.ruleList?.length
                          }
                          条）
                        </h4>
                        {this.renderRuleError(
                          detail?.rule_check_result?.rule_check_groups
                        )?.ruleList?.map((item, i) => (
                          <div
                            key={item}
                            style={{ paddingLeft: 10, marginBottom: 4 }}
                          >
                            {i + 1}、{item}
                          </div>
                        ))}
                        <div
                          style={{
                            background: "#FFEEDB",
                            color: "#FA8D14",
                            padding: 5,
                            borderRadius: "0 0 5px 5px",
                          }}
                        >
                          综合评分{detail?.rule_check_result?.score}，
                          {detail?.rule_check_result?.score_name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    background: "#fff",
                    padding: 20,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>费用清单</h3>
                  <a href="/dingtalk-audit-detail/list?type=expense">
                    点击查看
                    <RightOutlined />
                  </a>
                </div>
              </>
            ) : null}
            {detail?.pay_info?.write_off?.length ? (
              <div
                style={{
                  background: "#fff",
                  padding: 20,
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                <h3>关联核销单据</h3>
                <Table
                  columns={columns}
                  dataSource={detail?.pay_info?.write_off}
                  pagination={false}
                />
              </div>
            ) : null}
            {detail?.form_data?.tripGroup &&
            detail?.form_data?.tripGroup?.length ? (
              <div
                style={{
                  background: "#fff",
                  padding: 20,
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                <h3>行程明细</h3>
                {detail?.form_data?.tripGroup?.map((trip) => (
                  <div
                    style={{
                      background: "#fff",
                      padding: 20,
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                  >
                    {this.renderPhoneDescription("出发城市", trip?.start_city)}
                    {this.renderPhoneDescription("到达城市", trip?.target_city)}
                    {this.renderPhoneDescription(
                      "出行方式",
                      tripModes[trip?.trip_mode]
                    )}
                    {this.renderPhoneDescription(
                      "单程/往返",
                      trip?.round_trip ? "单程" : "往返"
                    )}
                    {this.renderPhoneDescription("开始时间", trip?.start_time)}
                    {this.renderPhoneDescription("结束时间", trip?.end_time)}
                    {this.renderPhoneDescription("时长/天", trip?.travel_days)}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}

        <ActivityCollapse workflowNodes={this.state.workflowNodes} />
        {showButton && (
          <div
            style={{
              width: "100%",
              background: "#fff",
              height: 80,
              boxShadow: "0 4px 10px 0px rgba(0,0,0,0.5)",
              position: "fixed",
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {type === "expense" && (
              <div
                style={{
                  marginRight: "5%",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={this.getPrintUrl}
              >
                <img src={print_icon} alt="" style={{ width: 28 }} />
                <div style={{ fontSize: 12, color: "#333", marginTop: 5 }}>
                  打印
                </div>
              </div>
            )}
            <div
              style={{
                marginRight: "8%",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => {
                Modal.confirm({
                  title: "评论",
                  content: (
                    <Input.TextArea
                      placeholder="请输入评论"
                      onChange={(e) =>
                        this.setState({ refuseReason: e?.target?.value })
                      }
                    />
                  ),
                  onOk: this.handleComment,
                  cancelText: "取消",
                  okText: "确认",
                });
              }}
            >
              <img src={comment_icon} alt="" style={{ width: 28 }} />
              <div style={{ fontSize: 12, color: "#333", marginTop: 5 }}>
                评论
              </div>
            </div>
            <Button
              loading={buttonLoading === "reject"}
              type="default"
              size="large"
              style={{
                marginRight: "4%",
                borderRadius: 6,
                width: 80,
                border: "1px solid #2D81FC",
                color: "#2D81FC",
              }}
              onClick={() => {
                Modal.confirm({
                  title: "确认",
                  content: (
                    <Input.TextArea
                      placeholder="请输入拒绝原因"
                      onChange={(e) =>
                        this.setState({ refuseReason: e?.target?.value })
                      }
                    />
                  ),
                  onOk: this.handleReject,
                  cancelText: "取消",
                  okText: "确认",
                });
              }}
            >
              拒绝
            </Button>
            <Button
              loading={buttonLoading === "approve"}
              type="primary"
              size="large"
              style={{
                borderRadius: 6,
                width: 120,
              }}
              onClick={() => {
                Modal.confirm({
                  title: "确认",
                  content: "确定通过吗?",
                  onOk: this.handleApprove,
                  cancelText: "取消",
                  okText: "确认",
                });
              }}
            >
              通过
            </Button>
            {/* {detail?.status === "APPROVED" &&
              (type !== "requisition" ||
                (type === "requisition" &&
                  detail?.template_type === "LOAN")) && (
                <Button
                  type="primary"
                  style={{ width: 100 }}
                  onClick={() => {
                    Modal.confirm({
                      title: "确认",
                      content: "确定支付吗?",
                      onOk: () => {},
                      cancelText: "取消",
                      okText: "确认",
                    });
                  }}
                >
                  支付
                </Button>
              )} */}
          </div>
        )}
        <img
          src={statusTagEurm[detail?.status]}
          className="staus_tag"
          style={statusTagStyle}
          alt=""
        />
      </div>
    );
  }
}

export default DingTalkAuditDetail;
