import moneyImg from "./assets/images/money.png";
const moneyPage = () => {
  return (
    <div>
      <img src={moneyImg} width="100%" alt="" />
    </div>
  );
};

export default moneyPage;
