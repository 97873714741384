import React, { useEffect, useState } from "react";
import download_app from "./assets/images/fpb_download.jpg";
import guide_cover from "./assets/images/guide_cover.png";

const PromotePage = () => {
  const [link, setLink] = useState(null);
  const [isWechat, setIsWechat] = useState(false);
  const [isIos, setIsIos] = useState(false);
  useEffect(() => {
    //获取浏览器的userAgent,并转化为小写
    var ua = navigator.userAgent.toLowerCase();
    //判断是否是苹果手机，是则是true
    var isIos = ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1;
    if (isIos) {
      // 跳转苹果官方商店
      setLink(
        "https://apps.apple.com/cn/app/%E5%8F%91%E7%A5%A8%E5%A4%A7%E5%B8%88/id1638674145"
      );
    } else {
      // 跳转安卓下载链接
      setLink("https://cdn.51kik.com/apk/fapiaobao.apk");
    }
    setIsIos(isIos);
    setIsWechat(ua.indexOf("micromessenger") !== -1);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <img
        src={download_app}
        alt=""
        style={{ width: "100%" }}
        onClick={() => (window.location.href = link)}
      />
      {isWechat && !isIos ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: "#000",
            position: "fixed",
            left: 0,
            top: 0,
            opacity: 0.6,
          }}
        >
          <img
            src={guide_cover}
            alt=""
            style={{ width: "80%", position: "absolute", right: "10px" }}
            onClick={() => (window.location.href = link)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PromotePage;
