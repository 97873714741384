import React, { useEffect } from "react";
import { Button, Carousel } from "antd";
import axios from "axios";
import { baseUrl } from "./utils";
import yunpiao_banner1 from "./assets/images/yunpiao_banner1.png";
import yunpiao_banner2 from "./assets/images/yunpiao_banner2.png";
import yunpiao_banner3 from "./assets/images/yunpiao_banner3.png";
import yunpiao_banner4 from "./assets/images/yunpiao_banner4.png";
import yunpiao_banner5 from "./assets/images/yunpiao_banner5.png";
import yunpiao_intor from "./assets/images/yunpiao_intor.jpg";

const EmailPage = (props) => {
  useEffect(() => {
    axios.get(`${baseUrl()}/accounting-ecs/api/v1/bpm?source=baiwang`);
  }, []);

  return (
    <div style={{ width: props?.isPhone ? "100%" : 375, margin: "0 auto" }}>
      <Carousel autoplay>
        <div>
          <img src={yunpiao_banner1} width="100%" alt="yunpiao_banner1" />
        </div>
        <div>
          <img src={yunpiao_banner2} width="100%" alt="yunpiao_banner2" />
        </div>
        <div>
          <img src={yunpiao_banner3} width="100%" alt="yunpiao_banner3" />
        </div>
        <div>
          <img src={yunpiao_banner4} width="100%" alt="yunpiao_banner4" />
        </div>
        <div>
          <img src={yunpiao_banner5} width="100%" alt="yunpiao_banner5" />
        </div>
      </Carousel>
      <div
        style={{
          width: "100%",
          padding: 10,
          background: "#fff",
          textAlign: "center",
        }}
      >
        <Button
          style={{
            background: "#FF6000",
            color: "#fff",
            fontWeight: 600,
            width: "100%",
            height: 60,
            fontSize: 18,
            borderRadius: 60,
          }}
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/cn/app/%E5%8F%91%E7%A5%A8%E5%A4%A7%E5%B8%88-%E5%8F%91%E7%A5%A8%E6%89%AB%E6%8F%8F%E8%AF%86%E5%88%AB%E6%9F%A5%E9%AA%8C%E6%89%93%E5%8D%B0%E6%8A%A5%E9%94%80%E7%A5%9E%E5%99%A8/id1615181263")
          }
        >
          【IOS】点我下载免费体验
          <p style={{ color: "#682206", fontWeight: "normal", fontSize: 12 }}>
            （苹果手机）
          </p>
        </Button>
      </div>
      <img src={yunpiao_intor} width="100%" alt="" />
      <div
        style={{
          width: "100%",
          padding: 10,
          background: "#fff",
          textAlign: "center",
        }}
      >
        <Button
          style={{
            background: "#FF6000",
            color: "#fff",
            fontWeight: 600,
            width: "100%",
            height: 60,
            fontSize: 18,
            borderRadius: 60,
          }}
          onClick={() =>
            (window.location.href = "https://cdn.8piao.com/apk/发票大师.apk")
          }
        >
          【安卓】点我下载免费体验
          <p style={{ color: "#682206", fontWeight: "normal", fontSize: 12 }}>
            （华为/小米/OPPO及其他安卓手机）
          </p>
        </Button>
      </div>
    </div>
  );
};

export default EmailPage;
