import { Table } from "antd";
import { formatPrice, formatDate } from "../../utils";
import InvoiceTableExpand from "./InvoiceTableExpand";

const InvoiceTable = (props) => {
  const invoiceCostDetail = (record) => {
    return (
      <div>
        <InvoiceTableExpand
          data={record?.spendings}
          detail={props.detail}
          businessList={props.businessList}
          cityItems={props.cityItems}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "发票",
      dataIndex: "fapiao",
      render: (_text, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20%",
                height: "70px",
                marginRight: "20px",
                cursor: "pointer",
              }}
            >
              {record?.attachment_list &&
              props?.fileType(
                props?.imgSrc?.find((v) => v.id === record?.id)?.fileName
              ) === "pdf" ? (
                <embed
                  src={
                    props?.imgSrc?.find((v) => v.id === record?.id)?.previewLink
                  }
                  type="application/pdf"
                  width="100%"
                  height="100%"
                />
              ) : props?.imgSrc?.find((v) => v.id === record?.id)
                  ?.previewLink ? (
                <img
                  src={
                    props?.imgSrc?.find((v) => v.id === record?.id)?.previewLink
                  }
                  width="100%"
                  height="100%"
                  alt=""
                />
              ) : null}
              {!record?.id ? (
                <div style={{ lineHeight: "70px" }}>无票费用</div>
              ) : null}
            </div>
            <div style={{ marginRight: "20px", width: "65%" }}>
              <div
                style={{
                  width: "99%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record?.customer?.register_name}
              </div>
              <div>
                {record?.invoice_date &&
                  formatDate(record?.invoice_date, "YYYY-MM-DD")}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "报销金额(元)",
      dataIndex: "reimbursement_amount",
      align: "right",
      render: (text) => <div>{text && formatPrice(text)}</div>,
    },
    // {
    //   title: "验真状态",
    //   dataIndex: "validated",
    //   render: (text) => {
    //     if (text == 1) {
    //       return <div>验真通过</div>;
    //     }
    //     if (text == 0) {
    //       return <div>未验真</div>;
    //     }
    //     if (text == -3) {
    //       return <div>校验码错误</div>;
    //     }
    //     if (text == -2) {
    //       return <div>查无此票</div>;
    //     }
    //     if (text == -1) {
    //       return <div>发票不一致</div>;
    //     }
    //   },
    // },
    // {
    //   title: "操作",
    //   dataIndex: "action",
    //   render: (text, record) =>
    //     record?.id ? (
    //       <div onClick={() => props?.openFileDetail(record?.id)}>
    //         <a>查看</a>
    //       </div>
    //     ) : null,
    // },
  ];

  return (
    <div>
      {props?.detail?.expense_list?.length ? (
        <Table
          rowKey={(record) => record.spending_id}
          columns={columns}
          dataSource={props?.detail?.expense_list}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <div>{invoiceCostDetail(record)}</div>
            ),
            rowExpandable: (record) => record?.spendings?.length > 0,
          }}
          scroll={{ x: 500 }}
        />
      ) : null}
    </div>
  );
};
export default InvoiceTable;
