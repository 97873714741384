import guidImg from "./assets/images/newuser_guide.png";
const EmailPage = () => {
  return (
    <div>
      <img src={guidImg} width="100%" alt="" />
    </div>
  );
};

export default EmailPage;
