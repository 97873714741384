import React, { useEffect, useState } from "react";
import {
  Spin,
  Button,
  Layout,
  Table,
  message,
  Modal,
  Radio,
  Row,
  Col,
} from "antd";
import axios from "axios";
import copy from "copy-to-clipboard";
import { formatDate, baseUrl, GetQueryString } from "../utils";
// import footer from "../assets/images/footer.png";
import "./index.css";
const { Header, Content } = Layout;
const { Column } = Table;

const Report = (props) => {
  const [expenseData, setExpenseData] = useState();
  const [expenseList, setExpenseList] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvalRecordFlag, setApprovalRecordFlag] = useState(true);
  const [itineraryFlag, setItineraryFlag] = useState(true);
  const [requisitionFlag, setRequisitionFlag] = useState(true);
  const [exportVisible, setExportVisible] = useState(false);

  const formatNowDate = (time) => {
    time = time * 1000;
    return formatDate(time, "YYYY-MM-DD");
  };

  const goodsListItemImg = () => {
    const imgList = document.getElementsByClassName("img");
    for (let i = 0; i < imgList.length; i++) {
      const w = imgList[i].naturalWidth;
      const h = imgList[i].naturalHeight;
      if (!props.isPhone) {
        if (w < h && h - w > 2000) {
          imgList[i].className = "imgBig";
        } else {
        }
      } else {
        imgList[i].style.width = "100%";
        imgList[i].style.height = "100%";
      }
    }
  };

  const getPdfUrl = () => {
    setLoading(true);
    let key = GetQueryString("key");
    axios
      .get(
        `${baseUrl()}/accounting-ecs/api/v2/reports/pdf/print?key=${key}&approvalRecordFlag=${approvalRecordFlag}&itineraryFlag=${itineraryFlag}&requisitionFlag=${requisitionFlag}`
      )
      .then((res) => {
        if (res.data.code === 0) {
          setLoading(false);
          const strUrl = res.data.data?.key;
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.target = "_blank";
          a.href = strUrl;
          a.click();
        }
        if (res.data.code !== 0) {
          setLoading(false);
          message.error("生成打印文件失败");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error("生成打印文件失败");
        console.log(error);
      });
  };

  const CopyUrl = () => {
    copy(window.location?.href);
    message.success("已复制");
  };

  const onChangeApprovalRecordFlag = (e) => {
    setApprovalRecordFlag(e.target.value);
  };

  const onChangeItineraryFlag = (e) => {
    setItineraryFlag(e.target.value);
  };

  const onChangeRequisitionFlag = (e) => {
    setRequisitionFlag(e.target.value);
  };

  useEffect(() => {
    document.title = "";
    let key = GetQueryString("key");
    axios
      .get(
        `${baseUrl()}/accounting-ecs/api/v1/reports/data/print/decrypt?key=${key}`
      )
      .then((res) => {
        if (res.data.code === 0) {
          setExpenseData(res.data.data);
          let newImg = [];
          setExpenseList(res.data.data?.expenseList);
          res.data.data?.expenseList.map((item) => {
            item?.attachments?.files?.map((img) => {
              if (img.fileType === "PREVIEW") {
                newImg.push({
                  fileType: img.fileType,
                  key: img.key,
                  tempLink: img.tempLink,
                  purposeLocale: item.purposeLocale,
                });
              }
            });
          });
          setImgList(newImg);
        }
      });
  }, []);

  return (
    <Layout
      style={{
        background: "#f5f5f5",
        width: props.isPhone ? "100%" : "800px",
        margin: "0 auto",
      }}
    >
      <Spin tip="正在生成打印文件..." spinning={loading}>
        {props.isPhone ? (
          <Header style={{ background: "#f5f5f5", textAlign: "center" }}>
            <Button type="primary" onClick={() => CopyUrl()}>
              复制链接并去电脑端打印
            </Button>
          </Header>
        ) : (
          <Header style={{ background: "#f5f5f5", textAlign: "center" }}>
            <Button type="primary" onClick={() => setExportVisible(true)}>
              打印
            </Button>
          </Header>
        )}
        <Content>
          <div
            style={{
              background: "#fff",
              pageBreakAfter: "always",
              width: props.isPhone ? "100%" : "800px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            className={props.isPhone ? "expense_phone" : "expense_page"}
          >
            <div style={{ position: "relative" }}>
              <div style={{ flexDirection: "row" }}>
                <div
                  style={{
                    marginTop: "30",
                    fontSize: "24px",
                    fontWeight: "500",
                    color: "#000",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  报销单
                </div>
                <div style={{ fontWeight: 500, textAlign: "center" }}>
                  编号：{expenseData?.code}
                </div>
              </div>
            </div>
          </div>
          <div
            className="document-header"
            style={{ minHeight: props.isPhone ? 600 : 800 }}
          >
            <Row>
              <Col span={8}>费用主体</Col>
              <Col span={16}>{expenseData?.companyName}</Col>
            </Row>
            <Row>
              <Col span={8}>单据类型</Col>
              <Col span={16}>{expenseData?.claimTypeName}</Col>
            </Row>
            <Row>
              <Col span={8}>报销说明</Col>
              <Col span={16}>{expenseData?.description}</Col>
            </Row>
            <Row>
              <Col span={8}>报销人</Col>
              <Col span={16}>{expenseData?.claimUsername}</Col>
            </Row>
            <Row>
              <Col span={8}>报销金额（元）</Col>
              <Col span={16}>{expenseData?.reimbursementTotalAmount}元</Col>
            </Row>
          </div>
          {/* <img className="page-footer" style={{background:'#fff', width:'100%', paddingTop:'40px'}} src={footer} alt='' /> */}
          <div style={{ height: 8 }} />
          <div
            style={{
              background: "#fff",
              pageBreakAfter: "always",
              width: props.isPhone ? "100%" : "800px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: props.isPhone ? 700 : 900,
            }}
            className={props.isPhone ? "expense_phone" : "expense_page"}
          >
            <div style={{ position: "relative" }}>
              <h2 style={{ marginTop: 20, textAlign: "center" }}>费用明细</h2>
              <div
                style={{
                  marginTop: 20,
                  pageBreakInside: "avoid",
                  fontSize: "0.14rem",
                }}
                className="table report-table"
              >
                <Table
                  bordered
                  className="expense_table"
                  dataSource={expenseList}
                  pagination={false}
                  style={{ color: "#f0f2f5", flex: 1 }}
                  footer={() => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div>
                        报销金额：{expenseData?.reimbursementTotalAmount}元
                      </div>
                    </div>
                  )}
                >
                  <Column
                    className="column_item"
                    title="用途"
                    dataIndex="purposeLocale"
                    key="purposeLocale"
                    width="15%"
                  />
                  <Column
                    className="column_item"
                    title="业务时间"
                    dataIndex="invoiceDate"
                    key="invoiceDate"
                    render={(text) => text && formatNowDate(text)}
                    width="25%"
                  />
                  {/* <Column className="column_item" title="开票分类" dataIndex="categoryLocale" key="categoryLocale" width="15%"/> */}
                  {/* <Column className="column_item" title="发票金额(元)" dataIndex="invoiceAmount" key="invoiceAmount" width="20%" /> */}
                  <Column
                    className="column_item"
                    title="报销金额"
                    dataIndex="reimbursementAmount"
                    key="reimbursementAmount"
                    width="20%"
                  />
                  <Column
                    className="column_item"
                    title="备注"
                    dataIndex="description"
                    key="description"
                    width="20%"
                  />
                </Table>
              </div>
            </div>
          </div>
          {/* <div 
          style={{ background: '#fff', pageBreakAfter: 'always', width: props.isPhone ? '100%' : '800px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          className={props.isPhone ? "expense_phone" : "expense_page"}
        >
          <div style={{ position:'relative'}}>
              <h2 style={{ marginTop: 20, textAlign: 'center'}}>审批流程</h2>
              <div style={{ marginTop: 20,pageBreakInside: 'avoid',fontSize:'0.14rem', }} className="table">
                {
                  expenseList.map((item)=>(
                    <Table
                      bordered
                      className="expense_table"
                      dataSource={item.expense}
                      pagination={false}
                      style={{ color: '#f0f2f5', flex: 1 }}
                    >
                      <Column className="column_item" title="审批节点" dataIndex="categoryLocale" key="categoryLocale"  width="15%"/>
                      <Column className="column_item" title="审批人" dataIndex="categoryLocale" key="categoryLocale" width="15%"/>
                      <Column className="column_item" title="审批时间" dataIndex="invoiceDate" key="invoiceDate" render={text => text && formatNowDate(text) } width="20%"/>
                      <Column className="column_item" title="审批意见" dataIndex="invoiceAmount" key="invoiceAmount" />
                    </Table>
                  ))
                }
              </div>
          </div>
        </div> */}
          {/* <img className="page-footer" style={{background:'#fff', width:'100%', paddingTop:'40px'}} src={footer} alt='' /> */}
          <div style={{ height: 8 }} />
          {imgList?.map((item, index) => (
            <div style={{ background: "#fff" }}>
              <div style={{ textAlign: "center" }}>
                {item?.tempLink && (
                  <img
                    className="img"
                    src={item?.tempLink}
                    onLoad={goodsListItemImg}
                    alt=""
                    style={{ width: "100%" }}
                  />
                )}
              </div>
              <div style={{ padding: 10 }}>
                {item?.purposeLocale ? `用途：${item?.purposeLocale}` : null}
              </div>
            </div>
          ))}
        </Content>
      </Spin>
      <Modal
        title="导出PDF"
        visible={exportVisible}
        okText="导出"
        maskClosable
        destroyOnClose
        onCancel={() => {
          setExportVisible(false);
        }}
        bodyStyle={{
          overflow: "hidden",
        }}
        footer={null}
      >
        <div>
          <div>
            <div style={{ marginBottom: "8px" }}> {"是否包含审批记录: "}</div>
            <Radio.Group
              onChange={onChangeApprovalRecordFlag}
              value={approvalRecordFlag}
            >
              <Radio value={true}> 包含</Radio>
              <Radio value={false}>不包含</Radio>
            </Radio.Group>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "8px" }}> {"是否包含行程单: "}</div>
            <Radio.Group onChange={onChangeItineraryFlag} value={itineraryFlag}>
              <Radio value={true}> 包含</Radio>
              <Radio value={false}>不包含</Radio>
            </Radio.Group>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "8px" }}>
              {" "}
              {"是否包含关联的申请单: "}
            </div>
            <Radio.Group
              onChange={onChangeRequisitionFlag}
              value={requisitionFlag}
            >
              <Radio value={true}>包含</Radio>
              <Radio value={false}>不包含</Radio>
            </Radio.Group>
          </div>
          <div>
            <Button
              type="primary"
              onClick={getPdfUrl}
              style={{ width: "100%", marginTop: "20px" }}
            >
              导出
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Report;
