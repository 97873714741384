const RuleScoreSummary = (props) => {
  const getSummaryTotalNum = (list, type) => {
    let number = 0;
    if (type === "total") {
      for (let i = 0; i < list?.length; i++) {
        number += list[i]?.total_num;
      }
    }
    if (type === "success") {
      for (let i = 0; i < list?.length; i++) {
        number += list[i]?.success_num;
      }
    }
    if (type === "warn") {
      for (let i = 0; i < list?.length; i++) {
        number += list[i]?.warn_num;
      }
    }
    if (type === "fail") {
      for (let i = 0; i < list?.length; i++) {
        number += list[i]?.fail_num;
      }
    }
    return number;
  };

  return (
    <div>
      <div style={{ fontWeight: 800, fontSize: 16, margin: "30px 0" }}>
        此单据共检查&nbsp;
        {props.ruleList && getSummaryTotalNum(props.ruleList, "total")}&nbsp;
        项，其中校验通过&nbsp;
        {props.ruleList && getSummaryTotalNum(props.ruleList, "success")}&nbsp;
        项，规则告警&nbsp;
        <span style={{ color: "#efbf2e" }}>
          {props.ruleList && getSummaryTotalNum(props.ruleList, "warn")}&nbsp;
        </span>
        项。综合评分：{props.score}分。&nbsp;
        <span>{props.suggest}。</span>
      </div>
    </div>
  );
};

export default RuleScoreSummary;
