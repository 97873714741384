import React, { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./utils";
import yunpiao_pc from "./assets/images/yunpiao_pc.png";

const EmailPage = (props) => {
  useEffect(() => {
    axios.get(`${baseUrl()}/accounting-ecs/api/v1/bpm?source=baiwang-pc`);
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <img src={yunpiao_pc} width="100%" alt="" />
    </div>
  );
};

export default EmailPage;
