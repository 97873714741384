import dingtalkadImg from "./assets/images/dingtalkadImg.png";
import { openTryoutSku } from "dingtalk-design-libs";
import { useEffect } from "react";

const Page = () => {
  const GetQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
    var context = "";
    if (r != null) context = r[2];
    reg = null;
    r = null;
    return context == null || context === "" || context === "undefined"
      ? ""
      : context;
  };

  const auth = () => {
    openTryoutSku({
      appId: "116703", // 应用的appId
      miniAppId: "5000000002737526", // 如果是三方小程序应用，需要设置一下自身的miniAppId。
      // corpId可以从应用首页的url上获取到
      corpId: GetQueryString("corpId"),
      // 从应用首页的url上获取到，url上参数名为 purchaseToken。
      token: GetQueryString("purchaseToken"),
    })
      .then()
      .catch(() => {
        // 钉钉侧出现了技术异常，比如打开弹窗失败等，出现概率非常低
      });
  };

  useEffect(() => {
    setTimeout(() => {
      auth();
    }, 500);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <img
        src={dingtalkadImg}
        alt=""
        style={{ width: "100%" }}
        onClick={auth}
      />
    </div>
  );
};

export default Page;
