import React, { useEffect, useState } from "react";
// import download_app from "./assets/images/fpb_download.jpg";
import guide_cover from "./assets/images/guide_cover.png";
import { Carousel } from "antd";

import axios from "axios";
import { baseUrl } from "./utils";

import image1 from "./assets/images/image1.png";
import image2 from "./assets/images/image2.png";
import image3 from "./assets/images/image3.png";
import image4 from "./assets/images/image4.png";
import image5 from "./assets/images/image5.png";

const PromotePage = () => {
  const [link, setLink] = useState(null);
  const [isWechat, setIsWechat] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [showCover, setShowCover] = useState(false);
  useEffect(() => {
    axios.get(`${baseUrl()}/accounting-ecs/api/v1/bpm?source=baiwang-scan`);
    //获取浏览器的userAgent,并转化为小写
    var ua = navigator.userAgent.toLowerCase();
    //判断是否是苹果手机，是则是true
    var isIos = ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1;
    if (isIos) {
      // 跳转苹果官方商店
      setLink(
        "https://apps.apple.com/cn/app/%E5%8F%91%E7%A5%A8%E5%A4%A7%E5%B8%88/id1615181263"
      );
    } else {
      // 跳转安卓下载链接
      setLink("https://cdn.8piao.com/apk/fapiaomaster.apk");
    }
    setIsIos(isIos);
    setIsWechat(ua.indexOf("micromessenger") !== -1);
  }, []);

  return (
    <div style={{ width: "100%", background: "#fff", minHeight: "100vh" }}>
      <Carousel dots={true}>
        {[image1, image2, image3, image4, image5].map((src, idx) => (
          <div key={idx}>
            <img
              src={src}
              alt={"img"}
              style={{ width: "100%" }}
            />
          </div>
        ))}
      </Carousel>
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 17, color: "#333333", marginBottom: 3 }}>
          我现在用发票大师管理发票
        </div>
        <div style={{ color: "#666666", fontSize: 15, marginBottom: 5 }}>
          收集、查重、查验、导出、报销...几分钟就搞定了 AI管理发票就是方便
        </div>
        <a
          style={{
            display: "flex",
            height: 36,
            background: "linear-gradient( 278deg, #5AA0FF 0%, #1A69FF 100%)",
            borderRadius: 36,
            fontWeight: 600,
            fontsize: 16,
            color: "#FFFFFF",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={()=>{
            if (isIos) {
              window.location.href = link;
            } else {
              if (isWechat) {
                setShowCover(true);
              } else {
                window.location.href = link;
              }
            }
          }}
        >
          下载APP
        </a>
      </div>
      {/* <img
        src={download_app}
        alt=""
        style={{ width: "100%" }}
        onClick={() => (window.location.href = link)}
      /> */}
      {showCover ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: "#000",
            position: "fixed",
            left: 0,
            top: 0,
            opacity: 0.6,
          }}
        >
          <img
            src={guide_cover}
            alt=""
            style={{ width: "80%", position: "absolute", right: "10px" }}
            onClick={() => (window.location.href = link)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PromotePage;
