import { Select } from "antd";
import React, { useState } from "react";

import notice from "../assets/images/brain.gif";
import zh from "../assets/images/zh.png";
import en from "../assets/images/en.png";

const maps = {
  "zh-cn": {
    title: "连连大脑",
    p1: "连连大脑",
    p2: zh,
    p3: "去体验",
    btn: "EN",
  },
  en: {
    title: "LianLian Brain",
    p1: "LianLian Brain",
    p2: en,
    p3: "Try it",
    btn: "CN",
  },
};

export default () => {
  const [language, setLanguage] = useState("zh-cn");

  const { title, p1, p2, p3, btn } = maps[language];

  document.title = title;

  return (
    <div
      style={{
        width: " 100%",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        "background-color": "#24367d",
      }}
    >
      <img src={notice} alt="" style={{ width: "100%" }} />
      <div
        style={{
          position: "absolute",
          top: 30,
          right: 30,
          width: 76,
          height: 26,
          "font-family": "PingFangSC, PingFang SC",
          "font-weight": 500,
          "font-size": 12,
          color: "#000000",
          "line-height": 15,
          "text-align": "center",
          "background-color": "rgba(255, 255, 255, 0.59)",
          display: "flex",
          "justify-content": "center",
          "align-items": "center",
          "border-radius": 13,
        }}
        onClick={() => setLanguage(language === "zh-cn" ? "en" : "zh-cn")}
      >
        {btn}
      </div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: "50%",
          width: "100%",
        }}
      >
        <div
          style={{
            "margin-bottom": "20px",
            "font-family": "DOUYINSANSBOLD, DOUYINSANSBOLD",
            "font-weight": "bold",
            "font-size": "35px",
            color: "#ffffff",
            "line-height": "40px",
            "text-align": "center",
          }}
        >
          {p1}
        </div>
        <div style={{ padding: "0 14%" }}>
          <img src={p2} alt="" style={{ width: "100%" }} />
        </div>
        <a
          style={{ display: "block", padding: "0 14%", "margin-top": 50 }}
          href="https://waoai.8piao.com/#/chat/69cc2215-6885-43dc-8f98-d8e678302120?title=连连大脑"
          target="_blank"
        >
          <div
            style={{
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
              width: "100%",
              height: "49px",
              "font-family": "AlibabaPuHuiTi_2_85_Bold",
              "font-size": "15px",
              color: "#26377f",
              "line-height": "21px",
              "text-align": "center",
              background: "linear-gradient(180deg, #adf9fe 0%, #86c7fd 100%)",
              "border-radius": "49px",
            }}
          >
            {p3}
          </div>
        </a>
      </div>
    </div>
  );
};
