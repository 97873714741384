/**
 * 报销单详情 发票清单每项的展开
 */
import { useState, useEffect } from "react";
import { Table, Modal } from "antd";
import { formatPrice, formatDate } from "../../utils";

const InvoiceTableExpand = (props) => {
  const [imgList, setImgList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState();

  const getImg = (token) => {
    let url = imgList.find((v) => v.token === token)?.previewLink;
    return url;
  };

  const openModal = (token) => {
    let url = imgList.find((v) => v.token === token)?.previewLink;
    setImgUrl(url);
    setVisible(true);
  };

  const closeModal = () => {
    setImgUrl();
    setVisible(false);
  };

  // const getPeersList = (peers) => {
  //   let list = [];
  //   peers?.forEach((item) => {
  //     item?.userName && list.push(item?.userName);
  //   });
  //   return list?.join('、');
  // };

  const getApportions = (apportion) => {
    let list = [];
    apportion?.forEach((item) => {
      item?.apportion_name && list.push(item?.apportion_name);
    });
    return list.join("、");
  };

  const columns = [
    {
      title: "报销金额（元）",
      dataIndex: "reimbursement_amount",
      render: (text) => text && formatPrice(text),
    },
    {
      title: "费用类型",
      dataIndex: "category_desc",
    },
    {
      title: "用途",
      dataIndex: "purpose_desc",
    },
    // {
    //   title: '操作',
    //   render: (_text, record) => (
    //     <a
    //       onClick={() => {
    //         setCurrentSpending(record);
    //         setShowSpendingDetailDrawer(true);
    //       }}
    //     >
    //       费用明细
    //     </a>
    //   ),
    // },
    // {
    //   title: '业务描述',
    //   dataIndex: 'description',
    // },
    // {
    //   title: '参与人员',
    //   dataIndex: 'peers',
    //   render: (text, record) => <div>{record?.peers && getPeersList(record?.peers)}</div>,
    // },
    {
      title: "时间",
      dataIndex: "expense_date",
      render: (text) => text && formatDate(text, "YYYY-MM-DD"),
    },
    {
      title: "承担部门",
      dataIndex: "apportions",
      render: (text, record) => (
        <div>{record?.apportions && getApportions(record?.apportions)}</div>
      ),
    },
    {
      title: "附件",
      dataIndex: "spending_attachments",
      render: (_text, record) => {
        return (
          <div>
            {record?.spending_attachments?.map((item) =>
              item.fileType === "ATTACHTMENT" ? (
                <img
                  src={getImg(item?.token)}
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => openModal(item?.token)}
                  alt=""
                  key={getImg(item?.token)}
                />
              ) : null
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    let pictureList = [];
    props.data?.forEach((item) => {
      item?.spending_attachments?.forEach((file) => {
        if (file?.fileType === "ATTACHTMENT") {
          pictureList.push({
            token: file.token,
            previewLink: file?.tempUrl,
          });
          setImgList([...pictureList]);
        }
      });
    });
  }, []);

  return (
    <div>
      <Table columns={columns} dataSource={props.data} pagination={false} />
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        title="附件"
        width={900}
        className="InvoiceTableExpandModal"
      >
        <img src={imgUrl} style={{ width: "100%" }} alt="" />
      </Modal>
    </div>
  );
};

export default InvoiceTableExpand;
