import moment from "moment";
export const formatDate = (datetime = "", format = "YYYY-MM-DD HH:mm:ss") =>
  moment(datetime).format(format);

export const baseUrl = () => {
  let url = "";
  const host = window?.location?.host;
  if (host === "invoice.8piao.com") {
    url = "https://api.8piao.com"; // 线上环境
  } else if (host === "172.16.1.21:7003") {
    url = "http://172.16.1.21:9080"; // 开发环境
  } else if (host === "172.16.1.32:7003") {
    url = "http://172.16.1.32:9080"; // 测试环境
  } else if (host.indexOf("localhost") !== -1) {
    url = "http://172.16.1.21:9080"; // 开发环境
    // url = 'http://172.16.1.32:9080'; // 测试环境
    // url = "https://api.8piao.com"; // 线上环境
  } else {
    // 如果都不匹配 优先保证线上环境的api没问题
    url = "https://api.8piao.com"; // 线上环境
  }
  return url;
};

export const formatPrice = (price) =>
  String((+price)?.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const GetQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null) context = r[2];
  reg = null;
  r = null;
  return context == null || context === "" || context === "undefined"
    ? ""
    : context;
};
