import { Card, Timeline } from "antd";
// import { CaretRightOutlined } from "@ant-design/icons";
import { formatDate } from "../../utils";

const ActivityCollapse = (props) => {
  const getStatus = (status, is_current) => {
    if (status === "Approval") {
      return "审批通过";
    } else if (status === "Reject") {
      return "审批拒绝";
    } else if (status === "Start") {
      return "";
    } else if (status === "Cancel") {
      return "审批撤回";
    } else {
      if (is_current) {
        return "审批中";
      } else {
        return "审批未开始";
      }
    }
  };

  const getStatusColor = (status) => {
    if (status === "Approval" || status === "Start") {
      return "green";
    } else if (status === "Reject" || status === "Cancel") {
      return "red";
    } else {
      return "gray";
    }
  };

  return (
    <Card style={{ width: "98%", margin: "0 auto", borderRadius: 10 }}>
      {/* <Collapse
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel key="1" header={`历史`}> */}
      <h3 style={{ marginBottom: 20 }}>审批流程</h3>
      <Timeline>
        {props?.workflowNodes?.map((activity) => (
          <Timeline.Item
            color={getStatusColor(activity?.approval_status)}
            key={activity?.task_id}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ color: "#333", fontSize: 14, fontWeight: 500 }}>
                <span style={{ marginLeft: 10 }}>{activity?.title}</span>
                <span className="text-primary-color" style={{ marginLeft: 10 }}>
                  {activity?.assignee_name}
                </span>
                <span style={{ marginLeft: 10 }}>
                  {getStatus(activity?.approval_status, activity?.is_current)}
                </span>
              </div>
              <div style={{ color: "#999", fontSize: 12, marginLeft: 10 }}>
                <span>
                  {activity?.end_time &&
                    formatDate(activity?.end_time, "HH:mm YYYY/MM/DD")}
                </span>
              </div>
            </div>
            {activity?.comments?.length ? (
              <div
                style={{
                  width: "100%",
                  background: "#efefef",
                  padding: "15px",
                  borderRadius: "8px",
                  marginTop: "6px",
                }}
              >
                {activity?.comments?.map((item) => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={item?.comment_id}
                  >
                    <div style={{ marginLeft: 10 }}>{item?.user_name}:</div>
                    <div style={{ marginLeft: 10 }}>{item?.message}</div>
                    <div
                      style={{
                        color: "#999",
                        fontSize: 12,
                        marginLeft: 10,
                      }}
                    >
                      <span>
                        {item?.time &&
                          formatDate(item?.time, "HH:mm YYYY/MM/DD")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </Timeline.Item>
        ))}
      </Timeline>
      {/* </Collapse.Panel>
      </Collapse> */}
    </Card>
  );
};

export default ActivityCollapse;
